
import './head-estim.css';
import creditsimg from '../credits_img.png';

  function ReactorHeadInfo({head, estim}) {
  return (
    <>
        <div className="reactor-div-head-estim">
            <img className="reactor-img-mascot-head" src={head}></img>
            <img className="reactor-img-mascot-head" src={head}></img>
            <img className="reactor-img-mascot-head" src={head}></img>
            x {estim}
            <img className="reactor-img-credits-info-cashore" src={creditsimg}></img>
        </div>
    </>
  );
}

export default ReactorHeadInfo;
