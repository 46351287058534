import React, { useState, useRef, useEffect } from "react";
import "./button-play.css";
import { useSnake } from "../SnakeContext";

const ButtonPlay = () => {
    const {startGame, moveSnake} = useSnake();
    return (
        <>
        <div className="div-playButton">
            <button onKeyDown={(e) => moveSnake(e)} onClick={startGame} className="playButton">
                <p>
                J<font className="reactor-Colorsecond">o</font>uer
                </p>
            </button>
        </div>
        
        </>
    );
};

export default ButtonPlay;