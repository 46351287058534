
import './connexion.css';
import { useState } from 'react';
import Formulaire from '../../components/Formulaire/Formulaire';
import { NavLink } from 'react-router-dom';


function ReactorConnexion() {

    const [etatconnexion, setEtatConnexion] = useState("connexion")

  return (
    <>
    <div className="Back-connexion">
        <div className='head-back-connexion'>
            <div className='formulaire-back-head-1'>
                <div className='spin1'>
                    <img src={`/img-connexion/back-head/head1.png`}></img>
                    <img src={`/img-connexion/back-head/head2.png`}></img>
                    <img src={`/img-connexion/back-head/head3.png`}></img>
                    <img src={`/img-connexion/back-head/head4.png`}></img>
                    <img src={`/img-connexion/back-head/head5.png`}></img>
                </div>
                <div className='spin2'>
                    <img src={`/img-connexion/back-head/head1.png`}></img>
                    <img src={`/img-connexion/back-head/head2.png`}></img>
                    <img src={`/img-connexion/back-head/head3.png`}></img>
                    <img src={`/img-connexion/back-head/head4.png`}></img>
                    <img src={`/img-connexion/back-head/head5.png`}></img>
                </div>
            </div>
            <div className='formulaire-back-head-2'>
                <div className='spin3'>
                    <img src={`/img-connexion/back-head/head3.png`}></img>
                    <img src={`/img-connexion/back-head/head7.png`}></img>
                    <img src={`/img-connexion/back-head/head2.png`}></img>
                    <img src={`/img-connexion/back-head/head5.png`}></img>
                    <img src={`/img-connexion/back-head/head6.png`}></img>
                    <img src={`/img-connexion/back-head/head9.png`}></img>
                </div>
                <div className='spin4'>
                    <img src={`/img-connexion/back-head/head3.png`}></img>
                    <img src={`/img-connexion/back-head/head7.png`}></img>
                    <img src={`/img-connexion/back-head/head2.png`}></img>
                    <img src={`/img-connexion/back-head/head5.png`}></img>
                    <img src={`/img-connexion/back-head/head6.png`}></img>
                    <img src={`/img-connexion/back-head/head9.png`}></img>
                </div>
            </div>
        </div>
    </div>
    

    <div className="block-connexion">
        <div className="div-info-connexion">
            <NavLink to="/" className="connexion-titre">
                React<font className="reactor-Colorsecond">o</font>r
            </NavLink>
            <div className="connexion-info-skin">
                <div className='skin-texte-connexion'>
                    <img src={`/img-connexion/skin-connexion.png`}></img>
                    T’es pas cap<br></br> de te connecter !
                </div>
                <div className='limeo-connexion'>
                    Design by 
                    <img src={`/img-connexion/Limeo-connexion.png`}></img>
                </div>
            </div>
        </div>
        <div className='Div-formulaire-connexion'>
            <Formulaire etatconnexion={etatconnexion} setEtatConnexion={setEtatConnexion}/>
        </div>
    </div>
    </>
  );
}

export default ReactorConnexion;
