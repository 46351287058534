import React, { useState, useRef, useEffect } from "react";
import "./CardSuccess.css";
import CreditsImg from '../../../credits_img.png';
import { Success } from "../DataSuccess";

function CardSuccess() {
    const listSuccess = Success.map(succes =>
        <li key={succes.id} className="">
            <div className="card-info">
                <div className="photo-coins">
                    <div className="card-photo">
                        
                    </div>
                    <div className="credits">
                        <div className="div-img-credits">
                            <img src={CreditsImg} className="credits-img"></img>
                        </div>
                        
                        <div className="credits-number">
                            <b>+{succes.price}</b>
                        </div>
                    </div> 
                </div>
            
                <div className="card-description">
                    <p className="title-text">{succes.title}</p>
                    <p className="text-description">{succes.description}</p>
                </div>
            </div>
        </li>    
    )

    return (
        <div className="">
            <ul className="grid-triomphes">{listSuccess}</ul>
        </div>
        
    );
};
export default CardSuccess;