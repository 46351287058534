import { useEffect, useRef, useState } from "react";
import "./xray.scss"

const Xray = ({currentLayer, setCurrentLayer}) => {
    const [size, setSize] = useState(120)
    const base = useRef()
    const layer1 = useRef()
    const layer2 = useRef()

    const ray = e => {
        const rect = e.target.getBoundingClientRect()
        const y = e.clientY - rect.top
        const x = e.clientX - rect.left
        const px = ((x / rect.width) * 100).toFixed(2)
        const py = ((y / rect.height) * 100).toFixed(2)

        base.current.style.setProperty("--size", size + "px")
        base.current.style.setProperty("--x", px + "%")
        base.current.style.setProperty("--y", py + "%")

        layer1.current.style.setProperty("--size", size + "px")
        layer1.current.style.setProperty("--x", px + "%")
        layer1.current.style.setProperty("--y", py + "%")

        layer2.current.style.setProperty("--size", size + "px")
        layer2.current.style.setProperty("--x", px + "%")
        layer2.current.style.setProperty("--y", py + "%")
    }

    const startray = e => {
        base.current.classList.add("hidden")
    }

    const unray = e => {
        base.current.classList.remove("hidden")
    }

    useEffect(() => {
        if (currentLayer === 1) {
            layer1.current.style.opacity = 1
            layer2.current.style.opacity = 0
            layer1.current.classList.add("hidden")
            layer2.current.classList.remove("hidden")
        }
        if (currentLayer === 2) {
            layer1.current.style.opacity = 0
            layer2.current.style.opacity = 1
            layer2.current.classList.add("hidden")
            layer1.current.classList.remove("hidden")
        }
    }, [currentLayer])


    return (
        <>
            <div className="xray-container">
                <div className="xray">
                    <img src="/xray/layer1.png" draggable={false} ref={layer1} className="layer hidden" onMouseMove={ray} onMouseEnter={() => startray()} onMouseLeave={() => unray()} />
                    <img src="/xray/layer2.png" draggable={false} ref={layer2} className="layer" onMouseMove={ray} onMouseEnter={() => startray()} onMouseLeave={() => unray()} />
                    <img src="/xray/base.png" draggable={false} ref={base} className="base hidden" onMouseMove={ray} onMouseEnter={() => startray()} onMouseLeave={() => unray()} />
                </div>
            </div>
        </>
    )
}
export default Xray