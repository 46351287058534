
import './formulaire.css';
import ReactorForm from '../../components/Formulaire/Form/Form';
import ReactorInscript from '../../components/Formulaire/Inscript/Inscript';


function ReactorFormulaire({etatconnexion, setEtatConnexion}) {
    function ChangeEtat(){
        if(etatconnexion == "connexion"){
            setEtatConnexion("inscription")
        }else if(etatconnexion == "inscription"){
            setEtatConnexion("inscriptionEmail")
        }else{
            setEtatConnexion("connexion")
        }
    }
  return (
    <>
    <div className='formulaire-connexion'>
        <div className='formulaire-titre'>
            {
                etatconnexion == "connexion" && "Connecte-toi"
            }
            {
                etatconnexion == "inscription" && "Inscris-toi"
            }
            {
                etatconnexion == "inscriptionEmail" && "Inscris-toi"
            }
        </div>
        {
            etatconnexion == "connexion" && <ReactorForm etatconnexion={etatconnexion}  setEtatConnexion={setEtatConnexion}/>
        }
        {
            etatconnexion == "inscription" && <ReactorInscript etatconnexion={etatconnexion}  setEtatConnexion={setEtatConnexion}/>
        }
        {
            etatconnexion == "inscriptionEmail" && <ReactorForm etatconnexion={etatconnexion}  setEtatConnexion={setEtatConnexion}/>
        }
        
        {
            etatconnexion == "connexion" && <hr></hr>
        }
        {
            etatconnexion == "inscriptionEmail" && <hr></hr>
        }

        <div className='formulaire-change-etat'>
            {
                etatconnexion == "connexion" && "Nouveau ?"
            }
            {
                etatconnexion == "inscriptionEmail" && "Déjà inscrit ?"
            }
            <button onClick={()=>{ChangeEtat()}}>
                {
                    etatconnexion == "connexion" && "S’inscrire à REACTOR"
                }
                {
                    etatconnexion == "inscription" && "S’inscrire avec une adresse e-mail"
                }
                {
                    etatconnexion == "inscriptionEmail" && "Se connecter à REACTOR"
                }
            </button>
        </div>
    </div>
    </>
  );
}

export default ReactorFormulaire;
