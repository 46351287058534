
import './reactor-x-ray.css';
import { useState } from 'react';
import Xray from '../../../components/xray/Xray';
import ReactorGameNav from '../../../components/gameNav/GameNav';
import ReactorXRayCercle from '../../../components/x-ray-cercle/X-Ray-Cercle';
import ReactorXRayBtnChange from '../../../components/X-Ray-btn-change/X-Ray-btn-Change';
import ReactorXRayTuyau from '../../../components/Tuyaux/Tuyaux';

function ReactorXRay() {
  const [currentLayer, setCurrentLayer] = useState(2)
  return (
    <>
      
      <main className="reactor-main">
        <div className="reactor-block-X-ray">
          <ReactorGameNav jeu={" > X-Ray.exe"}/>
          <div className="reactor-x-ray-phrase">
            Place à la science, Reactor a avalé des coins, 
            utilise notre brillante machine à rayon X pour les retrouver 
            (tu pourras les garder pour toi)
          </div>
          <div className="reactor-block-info-x-ray">
            <div className="x-ray-block-affichage">
              <ReactorXRayTuyau currentLayer={currentLayer}/>
              <div className="x-ray-box-affichage">
                <div className="x-ray-bords-affichage">
                  <div className="x-ray-mesure-affichage">
                    <div className="x-ray-graduation">
                      <img src={`/x-ray-img/graduation-bar.png`}></img>
                    </div>
                    <img src={`/x-ray-img/text-mesure.png`}></img>
                  </div>
                  <div className="x-ray-jeu-affichage">
                    <Xray currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} />
                    <div className="croix haut gauche">
                      <img src={`/x-ray-img/croix.png`}></img>
                    </div>
                    <div className="croix haut droite">
                      <img src={`/x-ray-img/croix.png`}></img>
                    </div>
                    <div className="croix bas gauche">
                      <img src={`/x-ray-img/croix.png`}></img>
                    </div>
                    <div className="croix bas droite">
                      <img src={`/x-ray-img/croix.png`}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="x-ray-block-panneau-commande-affichage">
              <div className="x-ray-btns-change">
                <ReactorXRayBtnChange currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} type={"Bones"}/>
                <ReactorXRayBtnChange currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} type={"Organs"}/>
              </div>
              <div className="x-ray-bas-btn-change">
                <div className="x-ray-bas-btn-change-ombre">
                </div>
              </div>
              <div className="x-ray-info-block box-arriere-commande">
                <img src={`/x-ray-img/Piece.png`}></img>
                <div className="x-ray-info-block-texte">
                  clique sur ta souris pour changer le type de rayon et trouve les 10 crédits du jour
                </div>
              </div>
              <div className="x-ray-bar-rayer">
              </div>
              <div className="x-ray-bouton-bas-box">
                <div className="x-ray-cercle-bas-box">
                  <ReactorXRayCercle currentLayer={currentLayer}/>
                  <ReactorXRayCercle currentLayer={currentLayer}/>
                  <ReactorXRayCercle currentLayer={currentLayer}/>
                </div>
                <div className="x-ray-mesure box-arriere-commande">
                  <img src={`/x-ray-img/mesure-x-ray-${currentLayer}.png`}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ReactorXRay;
