
import './reactor-footer.css';
import creditsimg from './components/credits_img.png';
import mascotdebase from './components/mackotbase.png';

function ReactorFooter() {
  return (
    <footer className="reactor-footer">
    <div className="reactor-footer-block-info">
      <div className="reactor-footer-credits reactor-shadow">
        <div className="reactor-footer-nombre-credits">
          <img className="reactor-img-credits" src={creditsimg}></img>
        </div>
        <div className="reactor-div-number-credits">
          <b>100</b>
        </div>
        <div className="reactor-footer-titre-credits">
          CRÉDITS
        </div>
      </div>
      <div className="reactor-footer-perso reactor-shadow">
        <img className="reactor-img-perso" src={mascotdebase}></img>
        <div className="reactor-block-footer-parole">
          <b><p>React<font className="reactor-Colorsecond">o</font>r</p></b>
          <p className="reactor-footer-message">On est pas le meilleur quand on le croit,<br></br> mais quand on le sait.</p>
        </div>  
      </div>
      
    </div>
    <div className="reactor-block-info-footer">
      Design with ♥ by <b>Limeo</b>
    </div>
    <div className="reactor-block-info-footer">
      Based on <b>React technology</b>
    </div>
  </footer>
  );
}

export default ReactorFooter;
