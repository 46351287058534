import React, { useState } from "react";
import "./ProfilMenu.css";
import ProfilInfo from "../profil-info/ProfilInfo";
import Mascotpicker from "../mascotpicker/Mascotpicker";
import ProfilInventaire from "../profil-inventaire/ProfilInventaire";
import ProfilTriomphes from "../profil-triomphes/ProfilTriomphes";
import Edit from "../../../img/profil/edit.png";

function ProfilMenu() {
    const [selected, setSelected] = useState(0);
    
    const handleItemClick = (index) => {
        setSelected(index);
    };

    const renderComponent = () => {
        if (selected === 0) {
          return <ProfilInfo />;
        } else if (selected === 1) {
          return <Mascotpicker />;
        } else if (selected === 2) {
          return <ProfilInventaire/>;
        } else if (selected === 3) {
            return <ProfilTriomphes />;
        } else {
          return null;
        }
    };
    
    const menuItems = [
        {
            id: 0,
            imageSrc : '/profil/menu/infos.png',
            imageSelected: '/profil/menu/InfosS.png',
            label: 'Mes infos'
        },
        {
            id: 1,
            imageSrc : '/profil/menu/skin.png',
            imageSelected: '/profil/menu/SkinS.png',
            label: 'Mon skin'
        },
        {
            id: 2,
            imageSrc : '/profil/menu/inventaire.png',
            imageSelected: '/profil/menu/InventaireS.png',
            label: 'Inventaire'
        },
        {
            id: 3,
            imageSrc : '/profil/menu/triomphe.png',
            imageSelected: '/profil/menu/TriompheS.png',
            label: 'Triomphes'
        },
    ]

    return (
        <div className="info-perso-boards">
            <div className="card">
                <div className="headProfile">
                    <div className="profile-photo">
                        <img src="/profil/menu/pp.png" className="pp"></img>
                        <img src={Edit} className="edit-PPimg"></img>
                    </div>
                    <div className="text-headProfile">
                        <h3>MoutinardMan</h3>
                        <p className="profile-level">Niveau 100</p> 
                    </div>
                     
                </div>
                <div className="grid-icons">
                    {menuItems.map((item) => (
                        <div
                            key={item.id}
                            className={`elem-icon ${selected === item.id ? 'selected' : ''}`}
                            onClick={() => handleItemClick(item.id)}
                        >
                            <img src={selected === item.id ? item.imageSelected : item.imageSrc} alt={item.label} ></img>
                            <p>{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="profil-component-choice">
                {renderComponent()}
            </div>
        </div>
    );
};
export default ProfilMenu;