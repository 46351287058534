import React, { useState, useRef, useEffect } from "react";
import Edit from "../../../img/profil/edit.png";
import "./ProfilInfo.css";

function ProfilInfo() {
    return (
        
        <div class="info-perso">
            <div className="info-header">
                <h2 className="info-title">Inf<font className="reactor-Colorsecond">o</font>rmations <br></br>personnelles</h2>
                <img src={Edit} className="edit-img"></img>
            </div>
            <form action="/page-traitement-donnees" method="post" className="form-info-perso">
                <div className="form-elem">
                    <label className="label-info-perso" for="prenom">Prénom</label>
                    <input type="text" className="input-info-perso" id="prenom" name="prenom" placeholder="Prénom" required></input>
                </div>
                <div className="form-elem">
                    <label className="label-info-perso" for="nom">Nom</label>
                    <input type="text" className="input-info-perso" id="nom" name="nom" placeholder="Nom" required></input>
                </div>
                <div className="form-elem">
                    <label className="label-info-perso" for="pseudo">Pseudo</label>
                    <input type="text" className="input-info-perso" id="pseudo" name="pseudo" placeholder="Pseudo" required></input>
                </div>
                <div className="form-elem">
                    <label className="label-info-perso" for="sujet">Genre</label>
                    <select name="sujet" className="input-info-perso" id="sujet" required>
                        <option value="probleme-compte">Femme</option>
                        <option value="probleme-compte">Homme</option>
                    </select>
                </div>
                <div className="form-elem">
                    <label className="label-info-perso" for="date">Date de naissance</label>
                    <input type="date" className="input-info-perso" id="date" name="date" required></input>
                </div>
                <div className="form-elem">
                    <label className="label-info-perso" for="email">Votre e-mail</label>
                    <input type="email" className="input-info-perso" id="email" name="email" placeholder="monadresse@mail.com" required></input>
                </div>
                <div className="form-elem">
                    <label className="label-info-perso" for="password">Mot de passe</label>
                    <input type="password" className="input-info-perso" id="password" name="password" required></input>
                </div>
                <div className="form-elem">
                    <button className="edit-password" >Modifier le mot de passe</button>
                </div>
            </form>
        </div>
    );
};
export default ProfilInfo;