
import './detailgame.css';
import ReactorJeVeuxJouer from '../jeveuxjouer/JeVeuxJouer';
import ReactorBlocktete from '../block-tete-detail-game/Block-Tete';

function ReactorDetailGame({jeu}) {
  return (
    <>
          <div className={`reactor-div-info-jeu reactor-border-jeux reactor-Jeu ${jeu}`}>
            <div className="reactor-div-info-jeu-choix">
              <div className="reactor-info-jeu-choix-titre">
                {jeu}
              </div>
              <div className="reactor-info-jeu-choix-texte">
                {
                    jeu == "Cashor" && "la machine à crédit infernale"
                }
                {
                    jeu == "X-Ray" && "Le rayon juste après le W-Ray"
                }
                {
                    jeu == "Snakor" && "Snake laser turbo 3000"
                }
                {
                    jeu == "Fast-Memory" && "memorise bien"
                }
                {
                    jeu == "Coming" && "arrive bientot"
                }
              </div>
              <ReactorJeVeuxJouer jeu={jeu} />
              
            </div>
            <div className="blocktetediv"> 
            {
                jeu == "Cashor" && <ReactorBlocktete jeu={jeu} />
            }
            {
                jeu == "Snakor" && <ReactorBlocktete jeu={jeu} />
            }
            </div>
          </div>
    </>
  );
}

export default ReactorDetailGame;
