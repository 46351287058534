
import './btn-info-jeux.css';

function ReactorBtnInfoJeux({setJeuDetail, jeu}) {
  return (
    <>
            <div onClick={() => {setJeuDetail(jeu)}} className={`btn-jeu reactor-btn-jeux reactor-Jeu reactor-border-jeux ${jeu}`}>
                <div className='divImg-card-game'>
                    <img src={`/choix-jeux/${jeu}.png`} className={`img-card-game ${jeu}`}></img>
                </div>
                <div className="reactor-jeu-titre Cashore">
                    {jeu}
                    <div className="reactor-jeu-soustitre x-ray">
                        {
                            jeu == "Cashor" && "Machine à sous"
                        }
                        {
                            jeu == "X-Ray" && "puzzle"
                        }
                        {
                            jeu == "Snakor" && "snake sauce maison"
                        }
                        {
                            jeu == "Fast-Memory" && "puzzle"
                        }
                        {
                            jeu == "Coming" && "soon"
                        }
                    </div>
                </div>
            </div>
    </>
  );
}

export default ReactorBtnInfoJeux;
