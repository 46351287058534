import "./btn-deplace.css";

function ReactorBtndeplace({placement}) {
  function click(placement){
    const scrollActu = document.querySelector(".reactor-block-slide-Jeux").scrollLeft
    if (placement == "gauche") {
      document.querySelector(".reactor-block-slide-Jeux").scrollTo({
        top: 0,
        left: scrollActu-350,
        behavior: 'smooth'
      })
    }else{
      document.querySelector(".reactor-block-slide-Jeux").scrollTo({
        top: 0,
        left: scrollActu+350,
        behavior: 'smooth'
      })
    }
    
  }
  return (
    <>
      <div onClick={() => {click(placement)}} className="reactor-Jeu reactor-border-jeux fleche reactor-block-info-header">
        {
        placement == "gauche" && <ion-icon name="chevron-back-outline"></ion-icon>
        }
        {
        placement == "droite" && <ion-icon name="chevron-forward-outline"></ion-icon>
        }
      </div>
    </>
  );
}

export default ReactorBtndeplace;
