export const Character = [
    {
        id: 0,
        name: 'Classic Reactor',
        citation: 'Le héros de tous les univers',
        price : 'Gratuit',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: 'Lorem ipsum dolor sit amet consectetur. Id facilisi maecenas hac habitant placerat turpis. Enim ac id neque nisi blandit non.',
        image: '/profil/1.png',
        head: 'head/1.png',
        body: 'body/1.png',
        foot: 'foot/1.png',
        primaryColor : "#E16464",
        secondaryColor : "#A43F3F",
        locked: false,
        isMonthly : false,
    },
    {
        id: 1,
        name: "Badbuzz l'éclair",
        citation: "Vers l'infini et les dollars !",
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/2.png',
        head: 'head/2.png',
        body: 'body/2.png',
        foot: 'foot/2.png',
        primaryColor : "#6CA4C7",
        secondaryColor : "#B3E782",
        locked: false,
        isMonthly : true,
    },
    {
        id: 2,
        name: 'Robocorn',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/3.png',
        head: 'head/3.png',
        body: 'body/3.png',
        foot: 'foot/3.png',
        primaryColor : "#639FBC",
        secondaryColor : "#ACDCF3",
        locked: false,
        isMonthly : false,
    },
    {
        id: 3,
        name: 'Iron Poney',
        citation: 'Made in China',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/4.png',
        head: 'head/4.png',
        body: 'body/4.png',
        foot: 'foot/4.png',
        primaryColor : "#D37557",
        secondaryColor : "#F4CE53",
        locked: false,
        isMonthly : false,
    },
    {
        id: 4,
        name: 'Bones',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/5.png',
        head: 'head/5.png',
        body: 'body/5.png',
        foot: 'foot/5.png',
        primaryColor : "#49BBC2",
        secondaryColor : "#89E8EE",
        locked: false,
        isMonthly : false,
    },
    {
        id: 5,
        name: 'X-Rayactor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/6.png',
        head: 'head/6.png',
        body: 'body/6.png',
        foot: 'foot/6.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },{
        id: 6,
        name: "Badbuzz l'éclair",
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/7.png',
        head: 'head/7.png',
        body: 'body/7.png',
        foot: 'foot/7.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 7,
        name: 'Robocorn',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/8.png',
        head: 'head/8.png',
        body: 'body/8.png',
        foot: 'foot/8.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 8,
        name: 'Iron Poney',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/9.png',
        head: 'head/9.png',
        body: 'body/9.png',
        foot: 'foot/9.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 9,
        name: 'Dark Mustang',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/10.png',
        head: 'head/10.png',
        body: 'body/10.png',
        foot: 'foot/10.png',
        primaryColor : "#362A2A",
        secondaryColor : "#D20808",
        locked: false,
        isMonthly : false,
    },
    {
        id: 10,
        name: 'X-Rayactor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/11.png',
        head: 'head/11.png',
        body: 'body/11.png',
        foot: 'foot/11.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 11,
        name: "Buzz l'éclor",
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/12.png',
        head: 'head/12.png',
        body: 'body/12.png',
        foot: 'foot/12.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 12,
        name: 'Robocop',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/13.png',
        head: 'head/13.png',
        body: 'body/13.png',
        foot: 'foot/13.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 13,
        name: 'Iron Manor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/14.png',
        head: 'head/14.png',
        body: 'body/14.png',
        foot: 'foot/14.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 14,
        name: 'X-Rayactor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/15.png',
        head: 'head/15.png',
        body: 'body/15.png',
        foot: 'foot/15.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 15,
        name: 'X-Rayactor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/16.png',
        head: 'head/16.png',
        body: 'body/16.png',
        foot: 'foot/16.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 16,
        name: "Buzz l'éclor",
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/17.png',
        head: 'head/17.png',
        body: 'body/17.png',
        foot: 'foot/17.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 17,
        name: 'Robocop',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/18.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 18,
        name: 'Iron Manor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/19.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 19,
        name: 'X-Rayactor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/20.png',
        primaryColor : "",
        secondaryColor : "",
        locked: false,
        isMonthly : false,
    },
    {
        id: 20,
        name: "Buzz l'éclor",
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/21.png',
        primaryColor : "",
        secondaryColor : "",
        locked: true,
        isMonthly : false,
    },
    {
        id: 21,
        name: 'Organ',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/22.png',
        primaryColor : "#AC2F40",
        secondaryColor : "#FF6076",
        locked: true,
        isMonthly : false,
    },
    {
        id: 22,
        name: 'Iron Manor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/23.png',
        primaryColor : "",
        secondaryColor : "",
        locked: true,
        isMonthly : false,
    },
    {
        id: 23,
        name: 'X-Rayactor',
        citation: 'Je suis ton géniteur !!!',
        price : '100',
        number: '165 B',
        power: '9000',
        charism: '9000',
        description: '',
        image: '/profil/24.png',
        primaryColor : "",
        secondaryColor : "",
        locked: true,
        isMonthly : false,
    },
]