
import './inscript.css';


function ReactorForm({etatconnexion, setEtatConnexion}) {
  return (
    <>
    <div className='bouton-inscription-div'>
      <button className='bouton-inscription'>
        <img src={`/img-connexion/Google.png`}></img>
        S’inscrire avec Google
      </button>
      <button className='bouton-inscription'>
        <img src={`/img-connexion/Facebook.png`}></img>
        S’inscrire avec Facebook
      </button>
    </div>
    </>
  );
}

export default ReactorForm;
