import "./Profil.css";
import ProfilMenu from "../../components/profil/profil-menu/ProfilMenu";

function ReactorProfil(){
    return (
        <main className="reactor-main page-game">
            <div className="reactor-main-profil">
                <div className="reactor-nav-profil">Profil</div>
                <div className="reactor-decalage">
                    <div className="info-perso-boards">
                        <ProfilMenu/>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default ReactorProfil;