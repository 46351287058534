
import { useEffect } from 'react';
import './titre-presentation.css';

function ReactorTitrePres(props) {
  return (
    <>
        <div className="reactor-titre-presentation">
            <font className="reactor-Colorsecond">{props.titre}</font>
        </div>
    </>
  );
}

export default ReactorTitrePres;
