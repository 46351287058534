import "./connect-affichage.css";
import { NavLink } from 'react-router-dom';

function Reactorconnectaffiche({connecter}) {
  return (
    <>
    {
        connecter == true &&
        <><a className="reactor-connect">Hello</a>, <a className="reactor-inscript">User</a></>

    }
    {
        connecter == false &&
        <><NavLink to="/Connexion" className="reactor-connect">Se connecter</NavLink><div className="reactor-line"></div><NavLink to="/Connexion" className="reactor-inscript">S'inscrire</NavLink></>
    }
    </>
  );
}

export default Reactorconnectaffiche;
