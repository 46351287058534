import React, { useState, useRef, useEffect } from "react";
import "./score-board.css";
import { useSnake } from "../SnakeContext";

function ScoreBoard() {
    const {score} = useSnake();
    return (
      <div className="scoreBox">
        <h2 className="score-title">
          Sc<font className="reactor-Colorsecond">o</font>re
        </h2>
        <h1 className="nb-points">{score} points</h1>
        <p className="highscore">HighScore : <font className="reactor-Colorsecond">{localStorage.getItem("snakeScore")} points</font> </p>
		  </div>
    );
};

export default ScoreBoard;