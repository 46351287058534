
import './x-ray-cercle.css';

function ReactorXRayCercle({currentLayer}) {
  return (
    <>
        <div className="x-ray-cercle box-arriere-commande">
            <div className={`x-ray-cercle-interieur tuyaux${currentLayer}`}>

            </div>
        </div>
    </>
  );
}

export default ReactorXRayCercle;
