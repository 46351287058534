import "./Vote.css";
import ReactorTitrePres from '../../components/titre-presentation/Titre-presentation';
import ReactorTextePres from '../../components/texte-presentation/Texte-presentation';
import SkinDuMois from '../../components/skin-du-mois/skin-mois.png';
import ombre from '../../components/ombre.png';
import CardVote from "../../components/card-vote/CardVote";

function ReactorVote() {

    return(
        <>
            <main className="reactor-main">
                <div className="reactor-main-vote">
                <div className="reactor-nav-vote">Vote</div>
                    <div className="container-vote">
                        <CardVote/>
                    
                    
                    <div className="reactor-presentation">
                        <div className="reactor-presentation-div">
                            <ReactorTitrePres titre={"Votez pour le prochain thème du skin du mois"} />
                            <ReactorTextePres txt={"mascote"} />
                        </div>

                        <div className="reactor-presentation-skin-mois-block">
                            <div className="reactor-presentation-skin-mois-block-titre">
                                <div className="reactor-presentation-skin-mois-titre">
                                Le Skin du m<font className="reactor-Colorsecond">o</font>is
                                </div>
                                <div className="reactor-presentation-skin-mois-titre-slogan">
                                    Vers l'infini et les dollars!
                                </div>
                                <div className="reactor-presentation-skin-mois-slogan-skin">
                                    BADBUZZ <br></br>L’ÉCLAIR
                                </div>
                            </div>
                            <div className="reactor-presentation-skin-mois-img-skin">
                                <img src={SkinDuMois}></img>
                                <img className="react-img-ombre" src={ombre}></img>
                            </div>
                            <div className="reactor-presentation-skin-mois-block-skin">
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default ReactorVote;