import React, { useState, useRef, useEffect } from "react";
import "./ProfilInventaire.css";
import PopupInventaire from "../popup-inventaire/PopupInventaire";
import { Character } from "../../DataInventaire";
import imglock from "../../lock.png";

function ProfilInventaire() {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCharacter, setSelectedCharacter] = useState(null);

    const handleCharacterClick = (character) => {
        setSelectedCharacter(character);
        setShowPopup(true);
    }
    const handleClosePopup = () => {
        setShowPopup(false);
    }

    

    const listPerso = Character.map((perso) => {
        const characterImgOpacity = {
            opacity: perso.locked ? 0.5 : 1,
            pointerEvents: perso.locked ? "none" : "auto",
        };

        return(
            <li key={perso.id} className="elem-list-character" onClick={() => {if (!perso.locked){handleCharacterClick(perso)}}}>
                <img className="character" src={perso.image} style={characterImgOpacity}/>
                {perso.locked && <img className="locked-image" src={imglock}/>}
            </li> 
        )
    });
    
    

    return (
        <>
        <div className="card-inventaire">
            <div className="profil-inventory">
                <h2 className="inventory-title">M<font className="reactor-Colorsecond">o</font>n inventaire</h2>
                <div className="grid-persos">
                    <ul className="grid-persos">{listPerso}</ul>
                </div>
            </div>
            {showPopup && <PopupInventaire character={selectedCharacter} onClose={handleClosePopup} />}
            
        </div>
        {showPopup && <div className="popupOpen" style={{position: "fixed", left: 0, top: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.5)'}}></div>}
        </>
        
    );
};
export default ProfilInventaire;