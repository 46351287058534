
import Reactorheader from "./reactor-header";
import ReactorMain from "./pages/home/reactor-main";
import ReactorFooter from "./reactor-footer";
import ReactorCashore from "./pages/jeux/slot-game/reactor-cashore";
import ReactorJeux from "./pages/jeux/Jeux";
import ReactorBoutique from "./pages/boutique/Boutique";
import ReactorXRay from "./pages/jeux/x-ray/reactor-x-ray";
import ReactorConnexion from "./pages/connexion/Connexion";
import ReactorProfil from "./pages/profil/Profil";
import ReactorVote from "./pages/vote/Vote";
import {
  Route,
  Routes,
  useMatch,
} from "react-router-dom";
import Snakor from "./pages/jeux/snake/Snakor";
import FastMemory from "./components/fastmemory/FastMemory";
import { CharacterProvider } from "./components/CharacterContext";

  function App() {
    
    const isHome = useMatch("/");
    const isConnexion = useMatch("/connexion");
  return (
    
      <div className="reactor">
        <CharacterProvider>
          {
            !isConnexion && <Reactorheader />
          }
          
          
          <Routes>
            <Route exact path="/" element={<ReactorMain />} />
            <Route exact path="/jeux" element={<ReactorJeux />} />
            <Route exact path="/jeux/cashor" element={<ReactorCashore />} />
            <Route exact path="/jeux/xray" element={<ReactorXRay />} />
            <Route exact path="/jeux/snakor" element={<Snakor />} />
            <Route exact path="/jeux/fastmemory" element={<FastMemory />} />
            <Route exact path="/boutique" element={<ReactorBoutique />} />
            <Route exact path="/profil" element={<ReactorProfil/>}/>
            <Route exact path="/vote" element={<ReactorVote/>}/>
            <Route exact path="/connexion" element={<ReactorConnexion />} />
          </Routes>
          {
            !isConnexion && <ReactorFooter />
          }
        </CharacterProvider>
      </div>
  );
}

export default App;
