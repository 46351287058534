import {useSnake, SnakeProvider} from "../../../components/snake/SnakeContext";
import ReactorGameNav from "../../../components/gameNav/GameNav";
import Sgame from "../../../components/snake/sGame/Sgame";
import ButtonPlay from "../../../components/snake/button-play/ButtonPlay";
import ScoreBoard from "../../../components/snake/score-board/ScoreBoard";
import perso from "../../../components/perso.png";
import barLight from "../../../components/barLight.png";
import GridRadar from "../../../components/gridRadar.png";
import RadarImg from "../../../components/radar-img.png";
import CoinsImg from "../../../components/credits_img.png";
import "./Snake.css";


const Snakor = () => {
    return (
    <>
    <main className="reactor-main">
        <div className="reactor-block-snakor">
            <div className="info-page-game">
                <ReactorGameNav jeu={" > Snakor.exe"}/>
                <div className="reactor-cashore-phrase">
                    Profitez d’un moment de détente grâce à cette machine à sous 100% gagnante
                </div> 
            </div>
            
            
            <div className="game">
                <SnakeProvider>
                    <div className="card-result">
                        <ButtonPlay></ButtonPlay>
                        <ScoreBoard></ScoreBoard>
                        <div className="points-board">
                            <ul className="list-pointsBoard">
                                <li className="elem-pointsBoard"><h3>1000</h3><p>=</p><h3 className="nb-coins">20</h3><img src={CoinsImg} className="coins-img"></img></li>
                                <li className="elem-pointsBoard"><h3>500</h3><p>=</p><h3 className="nb-coins">10</h3><img src={CoinsImg} className="coins-img"></img></li>
                                <li className="elem-pointsBoard"><h3>100</h3><p>=</p><h3 className="nb-coins">5</h3><img src={CoinsImg} className="coins-img"></img></li>
                                <li className="elem-pointsBoard"><h3>50</h3><p>=</p><h3 className="nb-coins">2</h3><img src={CoinsImg} className="coins-img"></img></li>
                                <li className="elem-pointsBoard"><h3>10</h3><p>=</p><h3 className="nb-coins">1</h3><img src={CoinsImg} className="coins-img"></img></li>
                            </ul>
                        </div>
                    </div>
                    <Sgame></Sgame>
                    <div className="deco-elements">
                        <div className="radar">
                            <img src={RadarImg} className="radar-img"></img>
                            <img src={GridRadar} className="grid-radar"></img>
                        </div>
                        <div className="bar-deco">
                            <img src={barLight} className="bar-light"></img>
                        </div>
                        <img src={perso} className="perso-img"></img>
                    </div>
                </SnakeProvider>
            </div>
        </div>
    </main>
    
    </>    
    )
}

export default Snakor