import React, { useState, useRef, useEffect } from "react";
import "./PopupInventaire.css";

function PopupInventaire({character, onClose, ...props}) {
    const handleClose = (e) => {
        e.target.parentNode.parentNode.style.animation = "popOut 1s linear"
        document.querySelector(".popupOpen").style.animation = "popOut 1s forwards"
        setTimeout(() => {
            
           onClose(); 
        }, 2000); 
        
    };

    return(
        <>
        <div className="div-popup" {...props}>
            <div className="popup" id="myPopup">
                <div className="popup-img">
                    <img src={character.image}></img>
                </div>
                <div className="popup-info">
                    <div className="info-entitled">
                        <h3 className="popupInfo-title">Nom : <font className="entitled">{character.name}</font></h3>
                        <h3 className="popupInfo-title">N° : <font className="entitled">{character.number}</font></h3>
                        <h3 className="popupInfo-title">Puissance : <font className="entitled">{character.power}</font></h3>
                        <h3 className="popupInfo-title">Charisme : <font className="entitled">{character.charism}</font></h3> 
                    </div>
                    
                    <div className="popup-description">
                        <p className="popup-text">{character.description} </p>
                        <p className="popup-text">Lorem ipsum dolor sit amet consectetur. Id facilisi maecenas hac habitant placerat turpis. Enim ac id neque nisi blandit non.Lorem ipsum dolor sit amet consectetur. Id facilisi maecenas hac habitant placerat turpis. Enim ac id neque nisi blandit non.</p>
                    </div>
                </div>
                <img className="cross-img" src="/profil/croix.png" onClick={handleClose}></img>
            </div>
        </div>
            
        </>
    )
}

export default PopupInventaire;