import "./boutique.css";
import ReactorBoutiqueAffiche from "../../components/Boutique-affichage/Boutique-Affiche";
import CardBoutique from "../../components/Boutique-affichage/CardBoutique";
import { Character } from "../../components/DataInventaire";
import { useState } from "react";

function ReactorBoutique() {
  const [credits, setCredits] = useState(10000);

  return (
    <>
      <main className="reactor-main reactor-main-b">
        <div className="main-boutique">
          <div className="reactor-nav-boutique">Boutique</div>
          <div className="reactor-main-boutique">
            
            <div className="boutique-container">
              <div className="reactor-container-boutique">
                <div className="card-list-boutique">
                  <CardBoutique perso={Character[1]} />
                  <CardBoutique perso={Character[0]} />
                  <CardBoutique perso={Character[9]} />
                </div>
                <div className="card-list-boutique">
                  <CardBoutique perso={Character[3]} />
                  <CardBoutique perso={Character[2]} />
                  <CardBoutique perso={Character[4]} />
                  <CardBoutique perso={Character[21]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ReactorBoutique;
