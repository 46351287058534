import React, { useState, useRef, useEffect } from "react";
import "./card-vote.css";

function CardVote() {
    const persoVote = [
        {
            id: 0,
            img: '/Vote/MasterOfUniverse.png',
            name: 'Master of Universe'
        },
        {
            id: 1,
            img: '/Vote/Naruto.png',
            name: 'Naruto'
        },
        {
            id: 2,
            img: '/Vote/Cendrillon.png',
            name: 'Cendrillon'
        },
    ]

    const [voted, setVoted] = useState(null);
    const [hasVoted, setHasVoted] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [votedtext, setVotedText] = useState(false);
    const handleItemClick = (index) => {
        setVoted(index);
        setVotedText(!votedtext);
        setHasVoted(true);
        setDisabled(true);
    };

    const listPersoVote = persoVote.map(perso =>
        <div className={(voted === perso.id ? 'voted card-container' : 'card-container')}>
            <li className={`elemPersoVote ${hasVoted && voted !== perso.id ? 'notVoted' : ''}`}>
                <div>
                    <img src={perso.img} className={`persoVoteImg  ${hasVoted && voted !== perso.id ? 'notVoted' : ''}`}></img>
                </div>
                <div className="persoVoteInfo">
                    <h3>{perso.name}</h3>
                    <button 
                        onClick={() => handleItemClick(perso.id)}
                        className={`btnVotePerso ${voted === perso.id ? 'voted' : ''} ${hasVoted && voted !== perso.id ? 'notVoted' : ''}`}
                        disabled={disabled}
                    >
                    {votedtext ? "J'ai voté" : "Voter"}
                    </button>
                </div>
            </li>
        </div>
    )
    return(
        <ul className="listPersoVote">{listPersoVote}</ul>
    )
}
export default CardVote;