
import { useEffect } from 'react';
import './texte-presentation.css';

function ReactorTextePres({txt}) {
  return (
    <>
        {
            txt == "mascote" &&
                <div className="reactor-text-presentation">
                    <p>
                        Bienvenue sur<b> Reactor</b>, le sanctuaire des amateurs de jeux en ligne.
                    </p><br></br>
                    <p>
                        Avec notre <b>Slot game</b>, vous avez la chance de gagner des "coins" en abondance et de personnaliser votre licorne robotique avec des skins incoryables.
                    </p><br></br>
                    <p>
                        <b>Rejoignez une communauté de joueurs passionnés</b> et faites la rencontre de notre licorne robotique alors qu'elle voyage à travers les mondes les plus fous de la culture pop. 
                    </p><br></br>
                    <p>
                        Avec la puissance de React, nous vous offrons une expérience de jeu fluide et captivante. Alors, prêts à relever le défi et à remporter les "coins"? <b>Rejoignez-nous sur Reactor maintenant!</b>
                    </p>
                </div>
        }
        {
            txt == "x-ray" &&
                <div className="reactor-text-presentation">
                    <p>
                    Lorem ipsum dolor sit amet consectetur. <b>Turpis fringilla felis</b> dis convallis et et turpis volutpat. Nisl amet donec malesuada nibh rutrum. Viverra sit nunc mi varius mi quis nunc. Tincidunt eget orci cursus orci sem tempor. Nulla ante orci vitae tempus.
                    </p><br></br>
                    <p>
                    Fames iaculis massa tellus sed pretium. Nisl tincidunt eget viverra ligula iaculis faucibus. Aenean felis praesent ut mi et eget ac id aliquet. Phasellus molestie ultrices nulla dictum semper. 
                    </p><br></br>
                    <p>
                    <b>
                        Suspendisse vulputate arcu netus urna faucibus. Proin cras felis hac posuere.
                    </b>
                    </p>
                </div>
        }
        {
            txt == "slot-game" &&
                <div className="reactor-text-presentation">
                    <p>
                    Lorem ipsum dolor sit amet consectetur. <b>Turpis fringilla felis</b> dis convallis et et turpis volutpat. Nisl amet donec malesuada nibh rutrum. Viverra sit nunc mi varius mi quis nunc. Tincidunt eget orci cursus orci sem tempor. Nulla ante orci vitae tempus.
                    </p><br></br>
                    <p>
                    Fames iaculis massa tellus sed pretium. Nisl tincidunt eget viverra ligula iaculis faucibus. Aenean felis praesent ut mi et eget ac id aliquet. Phasellus molestie ultrices nulla dictum semper. 
                    </p><br></br>
                    <p>
                    <b>
                        Suspendisse vulputate arcu netus urna faucibus. Proin cras felis hac posuere.
                    </b>
                    </p>
                </div>
        }
        
    </>
  );
}

export default ReactorTextePres;
