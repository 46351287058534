
import './form.css';
import { useState, useEffect } from 'react';


function ReactorForm({etatconnexion, setEtatConnexion}) {
    const [eye, setEye] = useState(false)
    useEffect(()=>{
        eye ? OpenEye() : CloseEye()
    }, [eye])


    function OpenEye(){
        document.getElementById("eye-close").style.display="none"
        document.getElementById("eye").style.display="block"
        document.getElementById("fpassword").type='texte'
    }
    function CloseEye(){
        document.getElementById("eye-close").style.display="block"
        document.getElementById("eye").style.display="none"
        document.getElementById("fpassword").type='password'
    }

  return (
    <>
        <form method='POST'>
        <label>
            Adresse e-mail
            <input type="email" name="femail" required/>
        </label>
        <label>
            Mot de passe
            <div className='formulaire-input-mdp'>
                <input type="password" name="fpassword" id="fpassword" required autocomplete="password"/>
                <div className='formulaire-img-eye-mdp'>
                    <img onClick={()=>{setEye(false)}} id='eye' src={`/img-connexion/eye-mdp.png`}></img>
                    <img onClick={()=>{setEye(true)}} id='eye-close' src={`/img-connexion/eye-close-mdp.png`}></img>
                </div>
            </div>
        </label>
        <div className='div-formulaire-oublier'>
            {
                etatconnexion == "connexion" && <a className='formulaire-oublie' href='#'>Mot de passe oublié ?</a>
            }
        </div>
        
        <label className='checkbox-formulaire'>
            <input type="checkbox" name="frememberme"/>
            {
                etatconnexion == "connexion" && "Se souvenir de moi"
            }
            {
                etatconnexion == "inscriptionEmail" && "Je veux être riche"
            }
        </label>
            
            {
                etatconnexion == "connexion" && <input className='submit' type="submit" value="Me connecter" />
            }
            {
                etatconnexion == "inscriptionEmail" && <input className='submit' type="submit" value="M’inscrire" />
            }
        </form>
    </>
  );
}

export default ReactorForm;
