
import './jeveuxjouer.css';
import { NavLink } from 'react-router-dom';

function ReactorJeVeuxJouer({jeu}) {
  return (
    <>
      <NavLink to={"/Jeux/"+jeu} className="reactor-je-veux-jouer">
        Je veux y jouer
      </NavLink>
    </>
  );
}

export default ReactorJeVeuxJouer;
