
import './tuyaux.css';

function ReactorXRayTuyau({currentLayer}) {
  return (
    <>
        <div className="x-ray-tuyaux-box">
            <img src={`/x-ray-img/Tuyaux-${currentLayer}.png`}></img>
        </div>
    </>
  );
}

export default ReactorXRayTuyau;
