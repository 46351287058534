import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useInterval } from "./sGame/useInterval";
import {
    CANVAS_SIZE,
    SNAKE_START,
    APPLE_START,
    SCALE,
    SPEED,
    DIRECTIONS
} from "./sGame/constants";
import appleImg from "../../components/snake/sGame/appleImage.png"

export const SnakeContext = createContext();

export const SnakeProvider = ({ children }) => {
    const canvasRef = useRef();
    const [snake, setSnake] = useState(SNAKE_START);
    const [apple, setApple] = useState(APPLE_START);
    const [dir, setDir] = useState([0, -1]);
    const [speed, setSpeed] = useState(null);
    const [gameOver, setGameOver] = useState(false);
    const [score, setScore] = useState(0);
    useInterval(() => gameLoop(), speed);

    useEffect(() => {
        if(!canvasRef.current) return
        const context = canvasRef.current.getContext("2d");
        context.setTransform(SCALE, 0, 0, SCALE, 0, 0);
        context.clearRect(0, 0, window.innerWidth, window.innerHeight);
        // Dessiner la grille
        const gridSize = 30; // Taille d'une cellule de la grille en pixels
        const numCols = Math.floor(window.innerWidth / gridSize);
        const numRows = Math.floor(window.innerHeight / gridSize);

        canvasRef.current.width = 500; // Ajuster la largeur du canvas
        canvasRef.current.height = 500; // Ajuster la hauteur du canvas
        
        context.strokeStyle = "#281C57"; // Couleur de la grille
        
        for (let col = 0; col < numCols; col++) {
            for (let row = 0; row < numRows; row++) {
                const x = col * gridSize;
                const y = row * gridSize;
                context.strokeRect(x, y, gridSize, gridSize);
            }
        };

        
        let deg = context.createLinearGradient(snake[0][0]*gridSize+(gridSize/2), snake[0][1]*gridSize+(gridSize/2) , snake[snake.length-1][0]*gridSize+(gridSize/2), snake[snake.length-1][1]*gridSize+(gridSize/2));
        deg.addColorStop(0, '#BC284A');
        deg.addColorStop(1, 'rgba(188, 40, 74, 0.1');
        context.fillStyle = deg;
        context.shadowColor = "#BC284A";
        context.shadowBlur = 15;
        snake.forEach(([x, y]) => {
            context.fillRect(x * gridSize, y * gridSize, gridSize, gridSize)
        });
        let fruit = document.getElementById("fruit")
        context.drawImage(fruit, apple[0] * gridSize, apple[1] * gridSize, gridSize, gridSize)
        
    },[snake, apple, gameOver]);

    const handleSetScore = () => {
        if (score > Number(localStorage.getItem("snakeScore"))) {
            localStorage.setItem("snakeScore", JSON.stringify(score))
        }
    };

    const startGame = () => {
        setSnake(SNAKE_START);
        setApple(APPLE_START);
        setDir([-1, 0]);
        setSpeed(SPEED);
        setScore(0);
        setGameOver(false);
        canvasRef.current.parentNode.click();
    };

    const checkCollision = (piece, snk = snake) => {
        if (
            piece[0] * SCALE >= CANVAS_SIZE[0] ||
            piece[0] < 0 ||
            piece[1] * SCALE >= CANVAS_SIZE[1] ||
            piece[1] < 0
        )
        return true;

        for (const segment of snk) {
            if (piece[0] === segment[0] && piece[1] === segment[1]) return true;
        }
        return false;
    };

    const moveSnake = ( e ) => {
        let keyCode = e.keyCode
        keyCode >= 37 && keyCode <= 40 && setDir(DIRECTIONS[keyCode]);
    }
    const createApple = () => 
        apple.map((_a, i) => Math.floor(Math.random() * (CANVAS_SIZE[i] / SCALE)));
        

    const checkAppleCollision = newSnake => {
        if (newSnake[0][0] === apple[0] && newSnake[0][1] === apple[1]) {
          let newApple = createApple();
          setScore(score + 1);
          while (checkCollision(newApple, newSnake)) {
            newApple = createApple();
          }
          setApple(newApple);
          return true;
        }
        return false;
    };
    
    const gameLoop = () => {
        const snakeCopy = JSON.parse(JSON.stringify(snake));
        const newSnakeHead = [snakeCopy[0][0] + dir[0], snakeCopy[0][1] + dir[1]];
        snakeCopy.unshift(newSnakeHead);
        if (checkCollision(newSnakeHead)){
            endGame();
        } 
        if (!checkAppleCollision(snakeCopy)) snakeCopy.pop();
        setSnake(snakeCopy);
    };

  const endGame = () => {
    setSpeed(null);
    setGameOver(true);
    handleSetScore();
  };

  return (
    <SnakeContext.Provider value={{ CANVAS_SIZE, canvasRef, useEffect, handleSetScore, startGame, checkCollision, moveSnake, createApple, checkAppleCollision, gameLoop, score, snake, endGame, gameOver, appleImg }}>
        {children}
    </SnakeContext.Provider>
  );
};
export const useSnake = () => useContext(SnakeContext);