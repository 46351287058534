
import './x-ray-btn-change.css';

function ReactorXRayBtnChange({currentLayer, setCurrentLayer, type}) {
    let activehead = ""
    
    function ActiveBones() {
        document.querySelector(".x-ray-btn-change-head.Organs").classList.remove("btn-head-active")
        document.querySelector(".x-ray-btn-change-head.Bones").classList.add("btn-head-active")
    }

    function ActiveOrgans() {
        document.querySelector(".x-ray-btn-change-head.Bones").classList.remove("btn-head-active")
        document.querySelector(".x-ray-btn-change-head.Organs").classList.add("btn-head-active")
    }

    function ChangeXRay() {
        if(type == "Bones"){
            if(currentLayer != 2){
                setCurrentLayer(2)
            }
        }else{
            if(currentLayer != 1){
                setCurrentLayer(1)
            }
        }
    }
    function ChoiceHead() {
        if(type == "Bones"){
            if(currentLayer == 2){
                activehead = "btn-head-active"
            }
        }else{
            if(currentLayer == 1){
                activehead = "btn-head-active"
            }
        }
    }

  return (
    <>
        <div className="x-ray-btn-change" onClick={ChangeXRay}>
            {
                ChoiceHead()
            }
            <div className={`x-ray-btn-change-head ${type} ${activehead}`}>
                <img src={`/x-ray-img/${type}-head.png`}></img>
                <div className="x-ray-ombre1-bocale ombre">
                    <img src={`/x-ray-img/ombre1-affichage.png`}></img>
                </div>
                <div className={`x-ray-ombre2-bocale ombre ombre2 ${type}`}>
                </div>
                <div className={`x-ray-ombre3-bocale ombre ombre3 ${type}`}>
                </div>
            </div>
            <div className="x-ray-btn-change-socle">
                <div className="x-ray-btn-change-socle-type">
                    {type}
                </div>
            </div>
        </div>
    </>
  );
}

export default ReactorXRayBtnChange;
