
import './reactor-header.css';
import ReactorNav from './components/navigation/Nav';
import { useState } from 'react';
import ReactorLockBlock from './components/block-lock/Block-Lock';
import Reactorconnectaffiche from './components/connection-affichage/Connect-Affichage';

  function ReactorHeader(props) {
  const [open, setOpen] = useState(false)
  const [connecter, setconnecter] = useState(false)

  return (
    <>
      <header className="reactor-header">
        <div className="reactor-div-menu-titre">
          <div className="reactor-block-info-header" onClick={()=>{
            setOpen(!open)
          }}>
            <ion-icon name="reorder-three-outline"></ion-icon>
          </div>
          <p>
            React<font className="reactor-Colorsecond">o</font>r.
          </p>
        </div>
        <div className="reactor-inscription">
          <Reactorconnectaffiche connecter={connecter} />
        </div>
        <div className="reactor-reseau">
            <ul>
              <li className="reactor-block-info-header"><ion-icon name="logo-facebook"></ion-icon></li>
              <li className="reactor-block-info-header"><ion-icon name="logo-twitter"></ion-icon></li>
              <li className="reactor-block-info-header"><ion-icon name="logo-instagram"></ion-icon></li>
              <li className="reactor-block-info-header"><ion-icon name="logo-linkedin"></ion-icon></li>
            </ul>
        </div>
      </header>
      <ReactorNav open={open} setOpen={setOpen} />
    </>
  );
}

export default ReactorHeader;
