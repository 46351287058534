import React, { useState, useRef, useEffect } from "react";
import "./sGame.css";
import { useSnake } from "../SnakeContext";

function Sgame() {
    const {moveSnake, canvasRef, CANVAS_SIZE, gameOver, appleImg} = useSnake();
    return (
        <div className="div-sGame">
           <div className="snake-game" role="button" tabIndex="0" onKeyDown={(e) => moveSnake(e)} >
                <canvas
                    className="canvas-snake"
                    ref={canvasRef}
                    width={CANVAS_SIZE[0] + "px"}
                    height={CANVAS_SIZE[1] + "px"}
                />
                <img id="fruit" src={appleImg} className="appleLogo"></img>
                {gameOver && <div className="gameOver">GAME OVER</div>}
            </div> 
        </div>
        
    );
};

export default Sgame;