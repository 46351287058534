import React, { useState, useRef, useEffect } from "react";
import './boutique-affiche.css';
import imglock from '../../components/lock.png';
import { useCharacter } from "../CharacterContext";

function CardBoutique({perso}){
    const {setCharacters} = useCharacter();
    const buyCharacter = () => {
        setCharacters(current => {
            return (
                [...current].map(character => {
                    if (character.id === perso.id) {
                        return character.locked = false
                    }
                })
                )
        })
    }

    const characterImgOpacity = {
        opacity: perso.locked ? 0.5 : 1,
    };

    return(
    <>
        <div className={perso.isMonthly ? "boutique-affiche-div-month" : "boutique-affiche-div"}>
            <div className="boutique-affiche-box" style={{backgroundColor : perso.primaryColor, "--after-color" : perso.secondaryColor}}>
                <div >
                    <img src={perso.image}className="Boutique-affiche-block-skin " style={characterImgOpacity}></img>
                    {perso.locked && <img className="lock-image" src={imglock}/>}
                </div>
                <div className={perso.isMonthly ? "Boutique-affiche-block-info-month" : "Boutique-affiche-block-info"}>
                    <div className={perso.isMonthly ? "perso-text-month" : "perso-text"}>
                        <div className="Boutique-affiche-info-nom">
                            {perso.name}
                        </div>
                        <div className="Boutique-affiche-info-cita">
                            {perso.citation}
                        </div>
                    </div>
                    
                    <div className="Boutique-affiche-prix-div">
                        <img className="boutique-affiche-piece" src={perso.locked ? "/img-Boutique-affichage/Piece.png" : "/img-Boutique-affichage/piece_achete.png"}></img>
                        <div className="Boutique-affiche-prix">
                            {perso.locked ? perso.price : "Acheté"}
                        </div>
                    </div>
                    <button className="Boutique-affiche-button-achete" onClick={perso.locked ? ()=>buyCharacter() :  ()=>{} }>
                        {perso.locked ? "J’achète" : "Déjà acheté"}
                    </button>
                </div>
            </div>
        </div>
    </>
    )
}

export default CardBoutique;