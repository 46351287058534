import { useEffect, useRef, useState } from "react"
import "./fastmemory.scss"

const FastMemory = () => {
    const totalItems = 5

    const timerRef = useRef();

    const [started, setStarted] = useState(false)
    const [nbItems, setNbItems] = useState(2)
    const [example, setExample] = useState()
    const [currentItems, setCurrentItems] = useState([1, 2])
    const [counter, setCounter] = useState(-1)
    const [counterMax, setCounterMax] = useState(-1)
    const [counterReset, setCounterReset] = useState(false)
    const [showing, setShowing] = useState(false)

    const randomItem = () => {
        return Math.floor(Math.random() * (totalItems) + 1)
    }

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }

        if (counter > 0) {
            timerRef.current = setTimeout(() => setCounter(counter - 1), 1000);
        }

        if (counter === 0) {
            if (showing) {
                setCounter(10)
                setCounterMax(10)
                setShowing(false)
            } else {
                setShowing(true)
            }
        }
    }, [counter]);

    const setup = () => {
        setNbItems(nbItems + 1)
        const _example = randomItem()
        let _items = []

        for (let i = 0; i < nbItems; i++) {
            let newItem = randomItem()
            while (newItem === _example) {
                newItem = randomItem()
            }
            _items.push(newItem)
        }
        _items[Math.floor(Math.random() * _items.length)] = _example

        setCounter(3)
        setCounterMax(3)
        setShowing(true)
        setExample(_example)
        setCurrentItems(_items)
    }

    const start = () => {
        setStarted(true)
        setup()
    }

    const select = (selected) => {
        if (selected === example) {
            setCounterReset(true)
            setup()
        }
    }

    return (
        <div className="fastmemory-container">
            {
                !started ?
                    <div className="button d-flex align-items-center justify-content-center my-2" onClick={() => start()}><h3>Jouer</h3></div>
                    :
                    <>
                        <div className="fastmemory-example">
                            <div className="fastmemory-example-head">
                                {
                                    showing ?
                                        <img width={100} src={"/fastmemory/icons/" + example + ".png"}></img>
                                        :
                                        <p>?</p>
                                }
                            </div>
                            {
                                showing ?
                                    <p className="m-0 fw-bold fs-5">{counter}s</p>
                                    :
                                    <div className="fastmemory-progress mt-3 mb-4">
                                        <div className="fastmemory-progress-content" style={{ width: ("calc(100% - " + (((counterMax - counter) / counterMax) * 400) + "px)") }}>
                                            <p className="m-0 fw-bold fs-5">{counter}s</p>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="fastmemory-items">
                            {
                                !showing &&
                                currentItems.map((item, index) => {
                                    return <img key={index} width={100} src={"/fastmemory/icons/" + item + ".png"} onClick={() => select(item)}></img>
                                })
                            }
                        </div>
                    </>
            }
        </div>
    )
}

export default FastMemory