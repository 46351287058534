
import './block-tete.css';

function ReactorBlocktete({jeu}) {
  return (
    <>
          <div className={`reactor-div-tete-info-detail reactor-border-jeux reactor-Jeu Golden ${jeu}`}>
            <img className="reactor-img-info-detail-jeux-head" src={`/img-detail/${jeu}-head1.png`}></img>
            <div className={`reactor-line ${jeu}`}></div>
            <img className="reactor-img-info-detail-jeux-head" src={`/img-detail/${jeu}-head2.png`}></img>
            <div className={`reactor-line ${jeu}`}></div>
            <img className="reactor-img-info-detail-jeux-head" src={`/img-detail/${jeu}-head3.png`}></img>
          </div>
    </>
  );
}

export default ReactorBlocktete;
