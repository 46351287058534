import { useEffect, useRef, useState } from "react"
import useSound from "use-sound"
import "./spinner.scss"

import slot_sound from "./audio/slot.mp3"
import action_sound from "./audio/action.mp3"
import win_sound from "./audio/win.wav"

import machineState_normal from "./machine/normal.png"
import machineState_loser from "./machine/loser.png"
import machineState_winner from "./machine/winner.png"
import machineState_playing from "./machine/playing.png"
import machineState_golden from "./machine/golden.png"
import machineState_newskin from "./machine/newskin.png"

const Spinner = () => {
    const [result, setResult] = useState([])
    const [canPlay, setCanPlay] = useState(true)
    const [machineState, setMachineState] = useState(machineState_normal)
    const spinner1 = useRef()
    const spinner2 = useRef()
    const spinner3 = useRef()
    const lever = useRef()

    const [slotSound] = useSound(slot_sound)
    const [actionSound] = useSound(action_sound)
    const [winSound] = useSound(win_sound)

    const start = () => {
        if (canPlay === false) return
        
        setCanPlay(false)
        setResult([])
        setMachineState(machineState_playing)

        lever.current.style.animation = "lever 1s ease forwards"
        lever.current.addEventListener('animationend', () => {
            lever.current.style.animation = ""
        })

        actionSound()
        setTimeout(() => {
            slotSound()
        }, 500)
        setTimeout(() => {
            winSound()
        }, 4000)
        spinner1.current.style.animation = "spin 0.5s 7 linear forwards .3s"
        spinner2.current.style.animation = "spin 0.5s 7 linear forwards .4s"
        spinner3.current.style.animation = "spin 0.5s 7 linear forwards .5s"

        setTimeout(() => {
            spinner1.current.append(...Array.from(spinner1.current.children).sort(function () {
                return Math.random() - 0.5;
            }));
            spinner2.current.append(...Array.from(spinner2.current.children).sort(function () {
                return Math.random() - 0.5;
            }));
            spinner3.current.append(...Array.from(spinner3.current.children).sort(function () {
                return Math.random() - 0.5;
            }));
        }, 800)

        spinner1.current.addEventListener('animationend', () => {
            spinner1.current.style.animation = "endSpin 0.4s ease forwards"
        })
        spinner2.current.addEventListener('animationend', () => {
            spinner2.current.style.animation = "endSpin 0.4s ease forwards"
        })
        spinner3.current.addEventListener('animationend', () => {
            spinner3.current.style.animation = "endSpin 0.4s ease forwards"
        })

        spinner3.current.addEventListener('animationend', () => {
            setResult([
                spinner1.current.children[0],
                spinner2.current.children[0],
                spinner3.current.children[0]
            ])
            setCanPlay(true)
        })
    }

    useEffect(() => {
        if(result.length === 0) return 
        if(result[0].currentSrc === result[1].currentSrc && result[0].currentSrc === result[2].currentSrc) {
            if(result[0].currentSrc.search("3.png") !== -1) { // 3 is golden skin id
                return setMachineState(machineState_golden)
            }
            return setMachineState(machineState_winner)
        }
        if(result[0].currentSrc !== result[1].currentSrc || result[0].currentSrc !== result[2].currentSrc) {
            return setMachineState(machineState_loser)
        }
    }, [result])

    return (
        <div className="spinner-machine-container">
            <img src={machineState} className="spinner-machine" />
            <div className="spinner-lever-container">
                <img src="/spinner/machine/lever.png" ref={lever} onClick={() => window.requestAnimationFrame(start)} className="spinner-lever" />
            </div>
            <div className="spinner-container">
                <div className="spinner-slot">
                    <div ref={spinner1} className="spinner-slot-container">
                        <img src="/spinner/icons/1.png"></img>
                        <img src="/spinner/icons/2.png"></img>
                        <img src="/spinner/icons/3.png"></img>
                        <img src="/spinner/icons/4.png"></img>
                        <img src="/spinner/icons/5.png"></img>
                        <img src="/spinner/icons/6.png"></img>
                        <img src="/spinner/icons/7.png"></img>
                    </div>
                </div>
                <div className="spinner-slot">
                    <div ref={spinner2} className="spinner-slot-container">
                        <img src="/spinner/icons/1.png"></img>
                        <img src="/spinner/icons/2.png"></img>
                        <img src="/spinner/icons/3.png"></img>
                        <img src="/spinner/icons/4.png"></img>
                        <img src="/spinner/icons/5.png"></img>
                        <img src="/spinner/icons/6.png"></img>
                        <img src="/spinner/icons/7.png"></img>
                    </div>
                </div>
                <div className="spinner-slot">
                    <div ref={spinner3} className="spinner-slot-container">
                        <img src="/spinner/icons/1.png"></img>
                        <img src="/spinner/icons/2.png"></img>
                        <img src="/spinner/icons/3.png"></img>
                        <img src="/spinner/icons/4.png"></img>
                        <img src="/spinner/icons/5.png"></img>
                        <img src="/spinner/icons/6.png"></img>
                        <img src="/spinner/icons/7.png"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Spinner