import { useEffect, useState } from "react"
import "./mascotpicker.scss"
import { useCharacter } from "../../CharacterContext";




const Mascotpicker = () => {
    const [btnChoice] = useState([
        {
            id: 0,
            imgSrc : '/mascotpicker/teteNS.png',
            imgSelected: '/mascotpicker/tete.png',
            label: 'Tête'
        },
        {
            id: 1,
            imgSrc : '/mascotpicker/busteNS.png',
            imgSelected: '/mascotpicker/buste.png',
            label: 'Buste'
        },
        {
            id: 2,
            imgSrc : '/mascotpicker/jambesNS.png',
            imgSelected: '/mascotpicker/jambes.png',
            label: 'Jambes'
        },
    ])

    const [activeButton, setActiveButton] = useState(0);
    
    const handleButtonClick = (index, part) => {
        console.log('Active Button', index);
        setActiveButton(index);
    };

    const {characters : defaultCharacters} = useCharacter();
    const [characters, setCharacters] = useState(defaultCharacters.filter(defaultCharacter => {
        return "head" in defaultCharacter
        
    }))
    const [preview, showPreview] = useState(false)
    const [previewContent, setPreviewContent] = useState({ part: "head" , items: defaultCharacters.map(character => {
        return character.head
    } ) })
    const [headPart, setHeadPart] = useState([defaultCharacters[0].head])
    const [bodyPart, setBodyPart] = useState([defaultCharacters[0].body])
    const [footPart, setFootPart] = useState([defaultCharacters[0].foot])




    useEffect(() => {
        console.log(characters)
        // if(!characters) return
        // setHeadParts(characters.map(character => {
        //     return character.head
        // } ));
        // setBodyParts(characters.map(character => {
        //     return character.body
        // } ));
        // setFootParts(characters.map(character => {
        //     return character.foot
        // } ))
    },[characters])
    

    function setItem(part, item) {
        switch (part) {
            case "head":
                setHeadPart(item)
                break;
            case "body":
                setBodyPart(item)
                break;
            case "foot":
                setFootPart(item)
                break;

            default:
                break;
        }
    }

    return (
        <div className="mascotpicker-container">
            <Selector setPreviewContent={setPreviewContent} btnChoice={btnChoice} setActiveButton={setActiveButton} handleButtonClick={handleButtonClick} previewContent={previewContent} characters={characters} setItem={setItem} head={headPart} body={bodyPart} foot={footPart} />
            <Preview show={preview} characters={characters} btnChoice={btnChoice} setActiveButton={setActiveButton} handleButtonClick={handleButtonClick} previewContent={previewContent} setItem={setItem} showPreview={showPreview} />
        </div>
    )
}

const Selector = (props) => {

    
    return (
        <div className="skin-view-choice">
            <div className="mascotpicker-item-container">
                <img 
                    className="mascotpicker-item head"
                    src={`/mascotpicker/character/${props.head}`} 
                    onClick={() => {
                        props.setPreviewContent(current => {
                            return { ...current, part: "head", items: props.characters.map(character => character.head) };
                        })}}
                />
                <img 
                    className="mascotpicker-item body"
                    src={`/mascotpicker/character/${props.body}`}
                    onClick={() => { 
                        props.setPreviewContent(current => {
                            return {...current, part : "body", items: props.characters.map(character => character.body)}
                        });
                        props.handleButtonClick(props.btnChoice[1].id);
                    }}
                />
                <img
                    className="mascotpicker-item foot"
                    src={`/mascotpicker/character/${props.foot}`}
                    onClick={() => { 
                        props.setPreviewContent(current => {
                            return {...current, part : "foot", items: props.characters.map(character => character.foot)}
                        });
                        props.handleButtonClick(props.btnChoice[2].id);
                    }}
                    />
            </div>
            
            <div className="buttons-choice-mascot">
                
                
                <button 
                    className={`choice-button ${props.previewContent.part === "head" ? 'activeButton' : ''}`} 
                    onClick={() => {
                        props.setPreviewContent(current => {
                            return { ...current, part: "head", items: props.characters.map(character => character.head) };
                        });
                    props.handleButtonClick(props.btnChoice[0].id);
                    }}
                >
                    <img src={props.activeButton === props.btnChoice[0].id ? props.btnChoice[0].imgSelected : props.btnChoice[0].imgSrc}></img>
                    Tête
                </button>

                <button 
                    className={`choice-button ${props.previewContent.part === "body" ? 'activeButton' : ''}`} 
                    onClick={() => { 
                        props.setPreviewContent(current => {
                            return {...current, part : "body", items: props.characters.map(character => character.body)}
                        });
                        props.handleButtonClick(props.btnChoice[1].id);
                    }}  
                >
                    <img src={props.activeButton === props.btnChoice[1].id ? props.btnChoice[1].imgSelected : props.btnChoice[1].imgSrc}></img>
                    Buste
                </button>

                <button 
                    className={`choice-button ${props.previewContent.part === "foot" ? 'activeButton' : ''}`} 
                    onClick={() => { 
                        props.setPreviewContent(current => {
                            return {...current, part : "foot", items: props.characters.map(character => character.foot)}
                        });
                        props.handleButtonClick(props.btnChoice[2].id);
                    }}  
                >
                    <img src={props.activeButton === props.btnChoice[2].id ? props.btnChoice[2].imgSelected : props.btnChoice[2].imgSrc}></img>
                    Jambes
                </button>
            </div>
            
        </div>
    )
}

const Preview = (props, placement) => {
    function click(placement){
        const scrollActu = document.querySelector(".mascotpicker-preview-container").scrollLeft
        if (placement == "gauche") {
          document.querySelector(".mascotpicker-preview-container").scrollTo({
            top: 0,
            left: scrollActu-350,
            behavior: 'smooth'
          })
        }else {
          document.querySelector(".mascotpicker-preview-container").scrollTo({
            top: 0,
            left: scrollActu+350,
            behavior: 'smooth'
          })
        }
        
      }
    return (
        <div className={"mascotpicker-preview"}>
            <div onClick={() => {click(placement='gauche')}}>
                <img src="/mascotpicker/arrow-left.png" className="arrow-skin"></img>
            </div>
            
            <div className="mascotpicker-preview-container">
                {
                    
                    props.previewContent.items.map((item, i) => {
                        return <img 
                                key={i} 
                                className="mascotpicker-preview-item" 
                                onClick={() => { 
                                    props.setItem(props.previewContent.part, item);
                                }} 
                        src={`/mascotpicker/character/${item}`} />
                    })
                }
            </div>
            <div onClick={() => {click(placement='droite')}} >
                <img src="/mascotpicker/arrow-right.png" className="arrow-skin" ></img>
            </div>
            
        </div>
    )
}

export default Mascotpicker