
import { useEffect } from 'react';
import './nav.css';
import { NavLink } from 'react-router-dom';

  function ReactorNav({open, setOpen}) {
  useEffect(()=>{
    open ? OpenMenu() : CloseMenu()
  }, [open])


  const OpenMenu = () => {
    document.getElementById("reactor-div-menu-laterral-titre").style.cssText="left: 0;";
    document.getElementById("reactor-list-nav").style.cssText="left: 0;";
    document.getElementById("reactor-menu-latteral").style.cssText="left: 0;";
    document.getElementById("reactor-CloseOut-Menu-latteral").style.cssText="right: 0;";
    document.getElementById("reactor-div-lateral").style.cssText="left: 0;";
  }
  const CloseMenu = () => {
    document.getElementById("reactor-menu-latteral").style.cssText="left: -100%;";
    document.getElementById("reactor-div-menu-laterral-titre").style.cssText="left: -100%;"; 
    document.getElementById("reactor-list-nav").style.cssText="left: -100%;";
    document.getElementById("reactor-CloseOut-Menu-latteral").style.cssText="right: 100%;";
    document.getElementById("reactor-div-lateral").style.cssText="left: -100%;";
  }


  
  return (
    <>
    <div className="reactor-div-lateral" id="reactor-div-lateral">
      <div className="reactor-menu-latteral" id="reactor-menu-latteral">
          <div className="reactor-div-menu-laterral-titre" id="reactor-div-menu-laterral-titre">
            <div className="reactor-block-info-header" onClick={()=>{
              setOpen(false)
            }}>
              <ion-icon name="close-outline"></ion-icon>
            </div>
            <p>
              React<font className="reactor-Colorprinc">o</font>r.
            </p>
          </div>
          <div className="reactor-Div-list-reseau">
            <div className="reactor-list-nav" id="reactor-list-nav">
              Menu.exe
              <ul>
                <li className='lockconnect'><NavLink to="/" className={({ isActive }) => isActive ? "reactor-choice-active" : "reactor-choice" } onClick={()=>{setOpen(false)}}>Home</NavLink></li>
                <li className='lockconnect'><NavLink to="/Profil" className={({ isActive }) => isActive ? "reactor-choice-active" : "reactor-choice" } onClick={()=>{setOpen(false)}}>Profil</NavLink></li>
                <li className='lockconnect'><NavLink to="/Jeux" className={({ isActive }) => isActive ? "reactor-choice-active" : "reactor-choice" } onClick={()=>{setOpen(false)}}>Jeux</NavLink></li>
                <li className='lockconnect'><NavLink to="/Boutique" className={({ isActive }) => isActive ? "reactor-choice-active" : "reactor-choice" } onClick={()=>{setOpen(false)}}>Boutique</NavLink></li>
                <li className='lockconnect'><NavLink to="/Vote" className={({ isActive }) => isActive ? "reactor-choice-active" : "reactor-choice" } onClick={()=>{setOpen(false)}}>Vote</NavLink></li>
              </ul>
            </div>
            <div className="reactor-reseau">
              <div className='reactor-reseau-titr'>
                Design by <b>Limeo</b>
              </div>
                <ul>
                  <li className="reactor-block-info-Nav"><ion-icon name="logo-facebook"></ion-icon></li>
                  <li className="reactor-block-info-Nav"><ion-icon name="logo-twitter"></ion-icon></li>
                  <li className="reactor-block-info-Nav"><ion-icon name="logo-instagram"></ion-icon></li>
                  <li className="reactor-block-info-Nav"><ion-icon name="logo-linkedin"></ion-icon></li>
                </ul>
            </div>
          </div>
      </div>
    </div>
    <div className="reactor-CloseOut-Menu-latteral" id="reactor-CloseOut-Menu-latteral" onClick={()=>{
      setOpen(false)
    }}>
    </div>
      
    </>
  );
}

export default ReactorNav;
