import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { Character } from "./DataInventaire";

export const CharacterContext = createContext();

export const CharacterProvider = ({ children }) => {
    const [characters, setCharacters] = useState(Character)
    
    return (
        <CharacterContext.Provider value={{characters, setCharacters}}>
            { children }
        </CharacterContext.Provider>
    );
};
export const useCharacter = () => useContext(CharacterContext);