import React, { useState, useRef, useEffect } from "react";
import "./ProfilTriomphes.css";
import CardSuccess from "./card-success/CardSuccess";


function ProfilTriomphes() {

    return (
        <div className="profil-triomphes">
            <div className="div-title-triomphes">
                <h2 className="triomphes-title">Mes tri<font className="reactor-Colorsecond">o</font>mphes</h2>
            </div>
            <CardSuccess/>
        </div>
        
    );
};
export default ProfilTriomphes;