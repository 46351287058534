
import './gamenav.css';
import { NavLink } from 'react-router-dom';


function ReactorGameNav({jeu}) {
  return (
    <>
          <div className="reactor-game-nav">
            <NavLink to="/Jeux">Jeux</NavLink>{jeu}
          </div>
    </>
  );
}

export default ReactorGameNav;
