export const Success = [
    {
        id: 0,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },
    {
        id: 1,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },
    {
        id: 2,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },
    {
        id: 3,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },
    {
        id: 4,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },
    {
        id: 5,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },{
        id: 6,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },
    {
        id: 7,
        title: 'Titre du succès',
        description: 'Lancer 100 fois la machine à sous sans jamais perdre espoir',
        price: '10',
        image: ''
    },
]